const zh = {
  L_BASIC: {
    TITLE: "东方至远云服务平台",
    DESCRIPTION:
      "东方至远云服务平台用于管理、交付、可视化InSAR监测数据结果，提供面向工程设施的分析服务，可快速实现建筑、地铁、道路、高铁、电力、水利、能源、保险等行业的风险辨识、分析及预防。",
    KEYWORD:
      "东方至远，遥感，影像服务，InSAR监测，InSAR，至远云，InSAR云平台，房屋形变分析，InSAR数据服务",
    NAV_0001: "首页",
    NAV_0002: "产品服务",
    NAV_0002_01: "InSAR数据服务",
    NAV_0002_02: "房屋形变分析",
    NAV_0002_02_01: "遥感大数据应急",
    NAV_0003_03: "地灾识别预警",
    NAV_0004_04: "建筑风险排查",
    NAV_0003: "使用指南",
    NAV_0004: "关于我们",
    NAV_0004_01: "公司介绍",
    NAV_0004_02: "联系我们",
    NAV_0005: "注册",
    NAV_0006: "登录",
    NAV_0007: "立即使用",
    NAV_0008: "没有权限",
    NAV_0009: "基本资料",
    NAV_0010: "修改密码",
    NAV_0011: "退出登录",
    NAV_0012: "产品订阅",
    FOOTER_0001: "至远官网",
    FOOTER_0002: "关于我们",
    FOOTER_0003: "联系我们",
    FOOTER_0004:
      "版权所有 © 2014- 2021  larkview.cn 北京东方至远科技股份有限公司",
    FOOTER_0005: "京ICP备18027436号-1",
    FOOTER_0006: "至远云",
    FOOTER_0007: "联系电话：400 699 1223, +86-10-88511338",
    FOOTER_0008: "邮箱：support@vastitude.cn",
    FOOTER_0009: "关注东方至远公众号",
    END_0001: "为明天 更安全",
  },
  L_MAIN: {
    TITLE: "首页",
    TITLE_002: "产品中心",
    BANNER_0000: "BIG DATA SERVICE",
    BANNER_0001: "对地观测大数据服务",
    BANNER_0002: "数据驱动决策变革",
    BANNER_0003: "提供大中城市",
    BANNER_0004: "长达7-12年的雷达卫星监测数据",
    BANNER_0005: "利用超算优势",
    BANNER_0006: "持续进行形变信息动态更新",
    BANNER_0007: "全球领先InSAR算法",
    BANNER_0008: "为用户提供专业数据服务",
    BANNER_0009: "用户按需选择",
    BANNER_0010: "提供高性价比空间地理信息服务",
    BUTTON_0001: "查看详情",
    BUTTON_0002: "联系我们",
    BUTTON_0003: "立即试用",
    BUTTON_0004: "立即使用",
    BUTTON_0005: "没有权限",
    BUTTON_0006: "您没有访问权限，如需获取权限，请与管理员联系！",
    PRODUCT_0001: "InSAR数据服务",
    PRODUCT_0002: "InSAR数据成果在线浏览与形变分析",
    PRODUCT_0003: "房屋形变分析",
    PRODUCT_0003_01: "遥感大数据应急平台",
    PRODUCT_0004: "基于InSAR技术的房屋形变风险评估",
    PRODUCT_0004_01: "基于3S技术的空天地一体化大数据应急平台",
    PRODUCT_0005: "历史数据",
    PRODUCT_0006: "丰富的雷达存档数据为风险评估提供依据",
    PRODUCT_0007: "拥有全球顶尖的卫星资源",
    PRODUCT_0008: "最快4天重访一次的监测影像",
    PRODUCT_0009: "丰富的卫星数据积累",
    PRODUCT_0010: "重点监测",
    PRODUCT_0011: "针对性监测重要工程设施或调查客户指定区域",
    PRODUCT_0012: "获取地区、行业和领域的历史数据",
    PRODUCT_0013: "监测人力难以到达区域的实时数据",
    PRODUCT_0014: "支持实地调查、决策制定、问题解决",
    PRODUCT_0015: "数据分析",
    PRODUCT_0016: "InSAR数据分析结果可视化",
    PRODUCT_0017: "InSAR数据成果在线浏览与分析",
    PRODUCT_0018: "工程设施的形变风险在线辨识",
    PRODUCT_0019: "建筑物形变分析和风险分级",
    PRODUCT_0020: "地灾隐患识别监测预警",
    PRODUCT_0021: "InSAR地质灾害隐患识别与监测预警",
    PRODUCT_0022: "城市建筑形变风险智能排查",
    PRODUCT_0023: "基于InSAR技术的城市建筑形变风险智能排查",
    PRODUCT_0031: "矿区空天地孔协同监测平台",
    PRODUCT_0031_01: "综合应用遥感、物联网、地面监测等技术对矿区进行协同监测",
    PRODUCT_0032: "风险普查成果可视化系统",
    PRODUCT_0032_01: "针对全国第一次风险普查成果设计开发的可视化系统",
    PRODUCT_0041: "天空地一体化建筑监测平台",
    PRODUCT_0041_01: "综合应用遥感、物联网等技术对建筑进行协同监测",
    PRODUCT_0042: "全国InSAR形变一张图",
    PRODUCT_0042_01: "",
    PRODUCT_0051: "综合遥感监测云服务平台",
    PRODUCT_0051_01: "应用光学遥感、雷达遥感进行协同监测",
    PRODUCT_0052: "专题事件集",
    PRODUCT_0052_01: "针对事件进行专题分析",
    PRODUCT_0061: "矿山采空区沉降AI智能识别与动态监测",
    PRODUCT_0061_01: "",
    PRODUCT_0062: "中小型水库安全监测系统",
    PRODUCT_0062_01: "",
    PRODUCT_0071: "建筑风险快速识别与监测系统",
    PRODUCT_0071_01: "基于InSAR和传感器等多源数据实现建筑风险研判管控",
    PRODUCT_0072: "一带一路国家地表形变一张图",
    PRODUCT_0072_01: "全球地表形变之一带一路国家地表形变一张图",
    PRODUCT_0081: "数字农场管理平台",
    PRODUCT_0081_01: "",
    PRODUCT_0082: "耕地态势监测系统",
    PRODUCT_0082_01: "",
    PRODUCT_0083: "海南雷达信息知识产品一张图",
    PRODUCT_0083_01: "",
    PRODUCT_0084: "广东雷达信息知识产品一张图",
    PRODUCT_0084_01: "",
    PRODUCT_0085: "雄安雷达信息知识产品一张图",
    PRODUCT_0085_01: "",
    PRODUCT_0086: "烟台市空天信息一体化平台",
    PRODUCT_0086_01: "",
    PRODUCT_0087: "伊金霍洛旗能源一张图",
    PRODUCT_0087_01: "",
    PRODUCT_0088: "澳门雷达信息知识产品一张图",
    PRODUCT_0088_01: "",
    KIND_0001: "城市",
    KIND_0002: "城市群、都市全域、重点区域",
    KIND_0003:
      "识别城市不稳定地区的地面运动位移情况，为提前进行风险防范、地下空间工程的施工和运营等提供精细监测支持",
    KIND_0004: "交通",
    KIND_0005: "高速铁路、高速公路、城市地铁、桥梁",
    KIND_0006:
      "针对高速铁路、高速公路和城市地铁等交通设施全生命周期进行沿线区域风险识别",
    KIND_0007: "保险",
    KIND_0008: "工程险、农业险、海上保险",
    KIND_0009:
      "基于云服务提供风险评估，降低应用和维护难度，协助理赔、辅助保险业“按图索赔”",
    KIND_0010: "建筑",
    KIND_0011: "单体建筑、建筑群",
    KIND_0012:
      "评估建筑的健康状况，掌握建筑物周边形变情况，快速发现问题区域，辅助进行地面调查",
    KIND_0013: "能源",
    KIND_0014: "油港、油库、输电塔",
    KIND_0015:
      "通过分析沉降区域的空间分布特点与时间序列变化特征，为能源设施的安全维护提供科学依据，保障其安全运营",
    SERVICE_0001: "服务方式",
    SERVICE_0002: "多样化服务方式满足不同需求",
    SERVICE_0003: "标准化产品",
    SERVICE_0004: "提供标准化InSAR数据成果展示及分析服务，满足用户基础需求",
    SERVICE_0005: "定制化产品",
    SERVICE_0006:
      "支持公有云、私有云及混合云灵活部署，提供API接口满足用户差异化需求",
    SERVICE_0007: "数据咨询服务",
    SERVICE_0008:
      "利用大数据，结合人工智能及城市/行业计算，提供专业数据咨询服务",
    PARTNER_0001: "合作伙伴",
    PARTNER_0002: "共建 共享 赋能",
    VIDEO_0001: "观看我们的视频教程",
    VIDEO_0002: "至远云LarkView产品功能简介",
    MENU_001: '基础数据服务',
    MENU_002: '应急管理',
    MENU_003: '城市建筑',
    MENU_004: '水利设施',
    MENU_005: '矿山安全',
    MENU_006: '农业遥感',
    MENU_007: '综合遥感',
    MENU_008: '专题事件',
    MENU_009: '雷达信息知识一张图',
    MENU_010: '能源管理',
  },
  L_ABOUT: {
    BANNER_0001: "关于我们",
    BANNER_0002: "为明天、更安全",
    NAV_0001: "公司介绍",
    NAV_0002: "联系我们",
    CONTENT_0001:
      "东方至远（VASTITUDE）是国内领先的基于时空大数据技术的设施健康诊断平台公司。",
    CONTENT_0002:
      "作为国内雷达遥感技术应用的领跑者，东方至远以“为明天，更安全”为使命，以“地球健康大数据专家”为愿景，始终聚焦城乡安全治理和设施健康诊治，依托遥感、云计算、物联网、大数据、人工智能等高新技术，可对城乡地质和设施风险灾害的事前预警预防、事中科学应急、事后救援指导全过程提供高效率、低成本、近实时的综合解决方案，为政府提供科学、高效、及时的决策支持，为行业提供专业、全面、高性价比的综合服务。",
    CONTENT_0003:
      "东方至远开创了遥感、InSAR和SAR技术在公共安全领域的工程化应用模式，推出了全球首个基于InSAR技术的云服务平台“至远云LARKVIEW”，拥有全国最大的InSAR和SAR技术的专业处理分析和应用服务团队，拥有世界顶级雷达卫星数据处理算法，已建成覆盖全球的高精度雷达卫星数据库和全球最大的中国城市健康大数据分析库。以全球21颗顶尖雷达及光学卫星资源和自主建立的卫星地面接收站为硬件支持，目前，东方至远已经实现从全球地表到单个基础设施细微形变的“体检、诊断和治疗指导”，以时空大数据为核心的地球健康全产业链正在不断完善、稳步形成。",
    CONTENT_0004: "东方至远——精诚所至 无远弗届",
    CONTENT_0005: "联系方式",
    CONTENT_0006: "地址：北京市海淀区花园北路14号环星大厦A座二层东侧",
    CONTENT_0007: "邮编：100091",
    CONTENT_0008: "电话：400 699 1223, +86-10-88511338",
    CONTENT_0009: "传真：+86-10-88511358",
    CONTENT_0010: "邮箱：support@vastitude.cn",
    CONTENT_0011: "微信公众号：",
  },
  L_DOC: {
    TITLE: "使用指南",
    NAV_0001: "InSAR数据服务",
    NAV_0002: "房屋形变分析",
    NAV_0003: "地灾识别预警",
    NAV_0004: "建筑风险排查",
    CONTENT_0001: "InSAR数据服务使用手册",
    CONTENT_0002:
      "InSAR数据服务用于发布重点城市的InSAR监测结果，平台拥有超过7年的卫星数据累积；支持查看InSAR数据成果分布情况，提供在线形变分析功能，可快速实现建筑物、地铁、道路、桥梁、高铁、电力、水利、能源等工程设施的风险筛查、风险分析、风险预防等。",
    CONTENT_0003: "一、登录",
    CONTENT_0004: "1、用谷歌、火狐或IE10及以上的浏览器访问网页：",
    CONTENT_0005: "https://www.larkview.cn/#/insar",
    CONTENT_0006: "。",
    CONTENT_0007:
      "2、点击【登录】按钮，在登录页面输入用户名和密码，点击【登录】完成登录。",
    CONTENT_0008: "3、点击【立即使用】按钮，进入InSAR数据服务页面。",
    CONTENT_0009: "二、InSAR数据展示",
    CONTENT_0010: "1、数据源：显示当前登录用户权限内的城市数据列表。",
    CONTENT_0010_01:
      "点击【数据源】下拉框，可以查看登录用户的授权数据项及基本信息。",
    CONTENT_0010_02: "点击列表中的勾选框可以选择多个数据源。",
    CONTENT_0010_03:
      "选择需要查看的数据源，在地图上默认以PS点形式展示选中数据源的形变分布情况。",
    CONTENT_0011: "2、图例：显示选中数据源的色带信息。",
    CONTENT_0012: "3、等值线：以等值线形式展示数据分布情况。",
    CONTENT_0012_01:
      "点击选中【等值线】按钮，在地图上以等值线形式展示形变分布情况。",
    CONTENT_0013: "4、数据分布：查看城市InSAR数据的分布情况。",
    CONTENT_0013_01: "点击选中【数据分布】按钮，在地图上标记已有数据的城市。",
    CONTENT_0014: "5、清除：清除地图上所有的分析点、线段及测量标记。",
    CONTENT_0015: "6、透明度：设置地图上PS点或等值线图层的透明度。",
    CONTENT_0055: "7、图例设置：自定义设置色带信息，包括色带范围及颜色。",
    CONTENT_0055_01:
      "设置色带范围，点击【应用】按钮，地图上的PS点或等值线随之更新。",
    CONTENT_0055_02: "选中任意颜色图标，点击删除按钮可以删除该颜色。",
    CONTENT_0055_03:
      "选中任意颜色图标，点击右侧的颜色设置按钮或者双击颜色图标，可以修改选中的颜色。",
    CONTENT_0055_04: "点击任意两个颜色之间的区域，可以新建一个颜色。",
    CONTENT_0055_05: "拖拽颜色图标至另一颜色图标上，可以修改两个颜色的位置。",
    CONTENT_0055_06:
      "选中任意推荐配色，点击【应用】按钮，地图上的PS点或等值线随之更新。",
    CONTENT_0055_07: "点击【重置】按钮，恢复初始设置。",
    CONTENT_0016: "三、InSAR数据分析",
    CONTENT_0017: "1、形变时序分析：显示选中点或面的形变时间序列曲线。",
    CONTENT_0017_01:
      "点击选中【形变时序分析】按钮，弹出形变时序分析选择窗口，支持点和面选择。",
    CONTENT_0017_02: "分析点：",
    CONTENT_0017_02_01: "a）选中【点】按钮，在地图上选择需要分析的点。",
    CONTENT_0017_02_02: "b) 弹出形变时序分析窗口，显示选中分析点的形变量曲线。",
    CONTENT_0017_02_03:
      "c) 点击选中【新建】按钮，地图上可选择多个分析点，取消选中【新建】按钮，地图上只能选中一个分析点。",
    CONTENT_0017_03: "分析面：",
    CONTENT_0017_03_01:
      "a）选中【面】按钮，在地图上选择需要分析的面，双击结束绘制。",
    CONTENT_0017_03_02: "b)  弹出形变时序分析窗口，显示选中面的形变量曲线。",
    CONTENT_0018:
      "2、剖面分析：显示选中线段上所有分析点的形变曲线及时间演化情况。",
    CONTENT_0018_01: "点击选中【剖面分析】按钮，鼠标为绘制线段状态。",
    CONTENT_0018_02:
      "在地图上选择需要分析的线段，双击结束绘制，并标记所有的分析点。",
    CONTENT_0018_03:
      "弹出剖面分析窗口，显示选中线段上所有分析点的平均形变速率曲线、累积形变量曲线及时间演化情况。",
    CONTENT_0019: "3、区域形变分析：查找指定形变范围的沉降风险区域。",
    CONTENT_0019_01: "点击选中【区域形变分析】按钮，弹出区域形变设置窗口。",
    CONTENT_0019_02: "设置分析区域的最大形变速率。",
    CONTENT_0019_03: "地图上显示查找的所有沉降风险区。",
    CONTENT_0019_04: "弹出区域形变分析窗口，显示数据分析列表和数据分析图表。",
    CONTENT_0019_05:
      "点击区域形变分析窗口的【数据报告下载】按钮，下载数据报告到本地。",
    CONTENT_0020: "4、分析报告：在线浏览选中城市的分析报告。",
    CONTENT_0021: "四、地图基础功能",
    CONTENT_0022: "1、搜索：查找搜索的地点，并在地图上标记。",
    CONTENT_0023: "2、定位您的城市：定位当前用户所在城市。",
    CONTENT_0024: "3、放大：将地图放大一个比例尺级别。",
    CONTENT_0025: "4、缩小：将地图缩小一个比例尺级别。",
    CONTENT_0057: "5、行政区：地图上显示行政区边界。",
    CONTENT_0026: "6、底图设置：切换地图的底图。",
    CONTENT_0027: "7、测量：在地图上测量距离和面积。",
    CONTENT_0028:
      "8、鹰眼：查看显示地图在整个地图中的位置，并支持更新地图显示视野窗口中的内容。",
    CONTENT_0029: "房屋形变分析使用手册",
    CONTENT_0030:
      "通过房屋形变分析，用户可选取或上传关注的房屋区域作为基础数据输入。平台基于用户的输入数据，结合用户关注区域的InSAR数据成果，自动实现房屋的在线形变分析，包括形变指标和关键点形变曲线，帮助用户及时了解房屋的安全状态。",
    CONTENT_0031: "一、登录",
    CONTENT_0032: "1、用谷歌、火狐或IE10及以上的浏览器访问网页：",
    CONTENT_0033: " https://www.larkview.cn/#/house",
    CONTENT_0034: "。",
    CONTENT_0035:
      "2、点击【登录】按钮，在登录页面输入用户名和密码，点击【登录】完成登录。",
    CONTENT_0036: "3、点击【立即使用】按钮，进入房屋形变分析页面。",
    CONTENT_0037: "二、新建房屋风险分析",
    CONTENT_0038: "1、新建房屋风险分析：创建需要查询的房屋区域。",
    CONTENT_0038_01: "点击选中【新建房屋风险分析】按钮，弹出新建窗口。",
    CONTENT_0038_02:
      "选择房屋区域：支持矩形选择、多边形选择及上传区域文件三种方式。",
    CONTENT_0038_03: "设置报告类型：选择报告类型，设置是否下载报告。",
    CONTENT_0038_04: "设置记录名称：自定义设置分析记录的名称。",
    CONTENT_0038_05: "点击提交按钮，显示提交窗口。",
    CONTENT_0039: "2、提交窗口：提示创建结果信息。",
    CONTENT_0039_01: "点击【分析记录】按钮，弹出分析记录窗口。",
    CONTENT_0039_02: "点击【继续新建】按钮，显示新建房屋风险分析窗口。",
    CONTENT_0040: "3、房屋分类：查看房屋的具体分类信息。",
    CONTENT_0040_01:
      "点击选中【房屋分类】按钮，地图上显示房屋分类标记及分类描述信息。",
    CONTENT_0041: "三、查看房屋形变分析结果",
    CONTENT_0042:
      "1、分析记录：显示创建的房屋分析记录及房屋分析结果，包括查询条件和分析记录列表。",
    CONTENT_0042_01: "点击选中【分析记录】按钮，弹出分析记录窗口。",
    CONTENT_0042_02: "设置查询条件，列表显示符合查询条件的分析记录。",
    CONTENT_0042_03:
      "点击操作栏的【定位】按钮，在地图上定位到选中记录的区域并显示区域边界。",
    CONTENT_0042_04:
      "点击操作栏的【查看】按钮，在地图上显示选中记录区域内的所有房屋并用颜色区分风险等级。",
    CONTENT_0042_05:
      "点击操作栏的【参考点】按钮，在地图上显示选中记录的参考点。",
    CONTENT_0042_06: "点击操作栏的【下载】按钮，将分析报告下载到本地。",
    CONTENT_0042_07: "点击操作栏的【删除】按钮，删除选中记录。",
    CONTENT_0043:
      "2、分析记录详情：显示创建的房屋分析记录及房屋分析结果，包括搜索和房屋列表。",
    CONTENT_0043_01: "点击分析记录的展开按钮，显示选中记录的详情。",
    CONTENT_0043_02: "设置查询条件，房屋列表显示符合查询条件的房屋。",
    CONTENT_0043_03:
      "点击列表中的一个房屋，在地图上标记选中的房屋，并弹出房屋形变信息窗口。",
    CONTENT_0043_04:
      "勾选房屋前面的勾选框，点击【下载选中】按钮下载选中房屋的分析报告。",
    CONTENT_0044: "3、房屋形变信息：显示选中房屋的关键点形变量曲线及形变指标。",
    CONTENT_0045:
      "在分析记录详情或者地图上选中一个房屋，弹出房屋形变信息窗口。",
    CONTENT_0046: "4、统计报告：在线浏览选中城市的房屋信息统计报告。",
    CONTENT_0047: "四、地图基础功能",
    CONTENT_0048: "1、搜索：查找搜索的地点，并在地图上标记。",
    CONTENT_0049: "2、定位您的城市：定位当前用户所在城市。",
    CONTENT_0050: "3、放大：将地图放大一个比例尺级别。",
    CONTENT_0051: "4、缩小：将地图缩小一个比例尺级别。",
    CONTENT_0052: "5、底图设置：切换地图的底图。",
    CONTENT_0053: "6、测量：在地图上测量距离和面积。",
    CONTENT_0054:
      "7、鹰眼：查看显示地图在整个地图中的位置，并支持更新地图显示视野窗口中的内容。",
    CONTENT_0060: "地灾识别预警服务使用手册",
    CONTENT_0061:
      "通过地质灾害隐患识别与监测预警平台，用户可快速筛选地质灾害信息，对相应区域的隐患进行识别；选择相应区域进行星弟监测，包括InSAR监测和地面监测、气象监测等；同时，用户可查看相应的地质灾害预报预警信息；查看区域的地址灾害统计分析情况。",
    CONTENT_0062: "https://www.larkview.cn/#/disaster",
    CONTENT_0063:
      "3、点击【立即使用】按钮，进入地质灾害隐患识别与监测预警系统页面。",
    CONTENT_0064: "二、信息查询",
    CONTENT_0065_01:
      "选择城市、对应区域，地质灾害类型、灾害等级、巡查情况、日期范围和关键词，搜索相应地质灾害列表。",
    CONTENT_0065_02: "点击地质灾害列表信息，查看地质灾害信息。",
    CONTENT_0066: "三、隐患识别",
    CONTENT_0067: "InSAR几何拍摄性能，切换性能数据展示类别",
    CONTENT_0068: "InSAR形变时序分析",
    CONTENT_0068_01: "a)选择相应PS点进行形变时序分析",
    CONTENT_0068_02: "b)矩形框选相应区域进行形变时序分析",
    CONTENT_0068_03: "c)多边形框选相应区域进行形变时序分析",
    CONTENT_0069:
      "区域智能化识别，选择省、市、区，形变速率，查询相应的区域列表",
    CONTENT_0070: "InSAR形变反演数据展示",
    CONTENT_0070_01: "a)选择相应点进行PS点时序分析",
    CONTENT_0070_02: "b)选择相应剖面线进行剖面分析",
    CONTENT_0071: "四、星地监测",
    CONTENT_0072:
      "InSAR监测，搜索展开相应的InSAR监测区域，查看相应区域的InSAR监测情况",
    CONTENT_0073: "气象监测，查看相应区域的气象信息",
    CONTENT_0074:
      "地面监测，选择相应区域，查看该区域的地面监测信息，包括雨量、表面位移、裂缝、水泥位等的监测情况",
    CONTENT_0075: "五、预报预警",
    CONTENT_0076:
      "预警分析，选择分析区域和分析模型，进行预警风险分析，打开预警风险分析图",
    CONTENT_0077: "地灾叠加分析",
    CONTENT_0078: "实时监测：实时监测区域进行分析",
    CONTENT_0079: "六、统计分析",
    CONTENT_0080:
      "对地灾信息进行统计 汇总，包括地灾数量、灾害类型占比、地理信息分布、险情等级分布、威胁人口数量等信息",
    CONTENT_0081: "七、图层管理",
    CONTENT_0082: "图层，选择相应的图层，展示对应的图层信息",
    CONTENT_0083: "图例，地图图例展示",
    CONTENT_0084: "八、地图基础功能",
    CONTENT_0085: "1、搜索:查找搜索城市、查找搜索城市地理位置点。",
    CONTENT_0086: "2、放大：将地图放大一个比例尺级别。",
    CONTENT_0087: "3、缩小：将地图缩小一个比例尺级别。",
    CONTENT_0088: "4、重叠：重置当前视图到地图页面。",
    CONTENT_0089: "5、图层控制：切换底图和选择展示图层内容。",
    CONTENT_0090: "6、罗盘：可以旋转视角，进行视角切换。",
    CONTENT_0091: "城市建筑物形变风险智能化排查系统使用手册",
    CONTENT_0092:
      "通过城市城市普查，用户可以查看相应城市的建筑物风险评估信息、SAR覆盖率、形变区面积及详细的普查信息。通过城市诊断，用户可以开展形变分析、风险建筑分析、单体建筑分析、风险区分析和网格分析；用户可对城市形变区域展开PS点形变时序分析、区域形变时序分析，用户也可选择城市中的单个建筑查看建筑物形变风险分析情况。同时，城市中的重点建筑，用户可进一步开展单体建筑分析，细化建筑物分析，细化建筑物形变风险分析结果；城市中不同地区的沉降情况不同。用户可通过选择沉降幅度，细化定位到城市不同区域，通过形变区用户可快速定位到城市搞风险沉降区域。用户可通过网格查询每平方公里区域内的高风险建筑形变风险情况。",
    CONTENT_0093: "一、登录",
    CONTENT_0094: "1、用谷歌、火狐或IE10以上的浏览器访问网页：",
    CONTENT_0095: "https://www.larkview.cn/#/building ",
    CONTENT_0096: "。",
    CONTENT_0097:
      "2、点击「登录」按钮，在登录页面输入用户名和密码，点击「登录」完成登录",
    CONTENT_0098:
      "3、点击「立即使用」按钮，进入城市建筑形变风险智能化排查系统页面。",
    CONTENT_0099: "二、城市普查",
    CONTENT_0100: "1、快速定位城市",
    CONTENT_0100_01: "点击城市列表中的城市可以快速定位到城市区域",
    CONTENT_0101: "2、切换城市普查图例信息",
    CONTENT_0101_01:
      "点击图例中风险建筑等级、SAR覆盖率和形变区，切换下图中对应区域暂时的风险等级颜色。",
    CONTENT_0102: "3、查看城市普查信息",
    CONTENT_0102_01:
      "点击下属列表中的区域或者上述地图中的地区位置，可以查看相应区域的不同风险等级的建筑数量和比例、SAR覆盖率和形变区面积情况。",
    CONTENT_0103: "三、城市诊断",
    CONTENT_0104: "1、快速定位城市",
    CONTENT_0104_01: "点击城市列表中的城市可以快速定位到城市区域",
    CONTENT_0105: "2、形变分析",
    CONTENT_0105_01: "PS点时序形变分析，选择相应区域的地点进行时序形变分析",
    CONTENT_0105_02: "PS矩形区域时序形变分析，选择相应矩形区域进行时序形变分析",
    CONTENT_0105_03:
      "PS多边形区域时序形变分析，选择相应多边形区域进行时序形变分析",
    CONTENT_0105_04: "PS剖面分析，选择剖面线进行剖面分析",
    CONTENT_0106: "3、风险建筑",
    CONTENT_0106_01: "通过区域和风险等级筛选建筑，筛选出相应地区的建筑列表",
    CONTENT_0106_02: "点选单个建筑，查看该建筑的形变分析信息",
    CONTENT_0106_03:
      "矩形框选区域建筑，展开区域建筑列表，查看框选区域内建筑形变分析信息",
    CONTENT_0106_04:
      "多边形框选区域建筑，展开区域建筑列表，查看框选区域内建筑形变分析信息",
    CONTENT_0106_05:
      "导入KML文件框选区域建筑，展开区域建筑列表，查看框选区域内建筑形变分析信息",
    CONTENT_0107: "4、单体建筑",
    CONTENT_0107_01: "通过区域和风险等级筛选单体建筑列表，查看建筑列表信息",
    CONTENT_0107_02: "点选单个建筑，查看该建筑的形变分析信息",
    CONTENT_0107_03:
      "矩形框选区域建筑，展开区域建筑列表，查看框选区域内建筑形变分析信息",
    CONTENT_0107_04:
      "多边形框选区域建筑，展开区域建筑列表，查看框选区域内建筑形变分析信息",
    CONTENT_0107_05:
      "导入KML文件框选区域建筑，展开区域建筑列表，查看框选区域内建筑形变分析信息",
    CONTENT_0108: "5、形变区",
    CONTENT_0108_01: "通过区域和形变幅度筛选相应的风险区，查看相应的风险区",
    CONTENT_0108_02: "查看风险区不同年份的情况",
    CONTENT_0108_03: "风险区按照年份进行演化的情况",
    CONTENT_0109: "6、网格",
    CONTENT_0109_01:
      "支持选择某点，展示该点所在的网格的形变风险等级，进一步查看该网格内的建筑的详细情况",
    CONTENT_0109_02: "支持划一条线段，展示该线段经过的网格的风险等级情况",
    CONTENT_0109_03: "支持矩形框选一块区域，展示该区域所在的网格的风险等级情况",
    CONTENT_0109_04:
      "支持多边形框选一块区域，展示该区域所在的网格的风险等级情况",
    CONTENT_0109_05:
      "支持导入kml文件选定区域，展示该区域所在的网格的风险等级情况",
    CONTENT_0110: "四、地图基础功能",
    CONTENT_0111: "1、搜索：查找搜索城市、查找搜索城市地理位置点",
    CONTENT_0112: "2、放大：将地图放大一个比例尺级别",
    CONTENT_0113: "3、缩小：将地图缩小一个比例尺级别",
    CONTENT_0114: "4、重置：重置当前视图到地图页面",
    CONTENT_0115: "5、图层控制：切换底图和选择展示图层内容",
    CONTENT_0116: "6、罗盘：可以旋转视角，进行视角切换",
  },
  L_ERROR: {
    TITLE: "Error",
    ERROR_0001: "您没有登录或登录超时！",
    ERROR_0002: "秒自动跳转到登录页",
    ERROR_0003: "立即登录",
    ERROR_0004: "返回首页",
    ERROR_0005: "您没有权限访问此页面！",
    ERROR_0006: "请联系管理员",
  },
  L_LOGIN: {
    LOGIN_TITLE: "欢迎登录",
    LOGIN_0001: "请输入用户名",
    LOGIN_0002: "请输入登录密码",
    LOGIN_0003: "请输入验证码",
    LOGIN_0004: "看不清，点击换一张。",
    LOGIN_0005: "请输入手机号",
    LOGIN_0006: "登录成功！",
    LOGIN_0007: "登录失败！",
    BUTTON_0001: "登录",
    BUTTON_0002: "立即注册",
    BUTTON_0003: "手机号登录",
    BUTTON_0004: "密码登录",
    BUTTON_0005: "获取验证码",
    BUTTON_0006: "忘记密码？",
  },
  L_PRODUCT: {
    TITLE_0001: "InSAR数据服务",
    TITLE_0002: "房屋形变分析",
    TITLE_0003: "地灾识别预警",
    TITLE_0004: "建筑风险排查",
    CONTENT_0001:
      "支持面向工程设施的形变风险在线辨识，提供主要城市的InSAR数据成果在线浏览与初步形变分析，快速实现对建筑物、地铁、道路、高铁、电力、水利、能源等工程设施的风险筛查、分析、预防等。",
    CONTENT_0002: "产品功能",
    CONTENT_0003_1: "形变分布概览",
    CONTENT_0003_2: "时序形变分析",
    CONTENT_0003_3: "剖面分析",
    CONTENT_0003_4: "风险区域筛查",
    CONTENT_0004: "产品优势",
    CONTENT_0005: "丰富的城市存档数据",
    CONTENT_0006: "提供大中城市长达7-12年的监测数据供用户选择",
    CONTENT_0007: "动态监测地表变化",
    CONTENT_0008: "基于多平台、多时相和多源数据对地表环境时空变化进行动态观测",
    CONTENT_0009: "城市地表变化规律可视化",
    CONTENT_0010: "快速、直观、全面的呈现城市整体的时空形变趋势",
    CONTENT_0011: "深度数据分析服务",
    CONTENT_0012: "提供可定制化的城市设施深度分析服务",
    CONTENT_0013: "应用场景",
    CONTENT_0014: "地质灾害预防",
    CONTENT_0015: "城市沉降评估",
    CONTENT_0016: "市政工程维护",
    CONTENT_0017: "轨道交通运营",
    CONTENT_0018: "电力输送保障",
    CONTENT_0019: "能源设施监测",
    CONTENT_1001:
      "通过对InSAR监测结果的进一步挖掘，分析每栋房屋的形变信息，发现城市内存在潜在风险的房屋，帮助用户及时了解房屋的安全状态，为房屋设计、施工及运维阶段的安全管理提供数据支撑。",
    CONTENT_1002: "按需创建对象",
    CONTENT_1003: "关键点曲线分析",
    CONTENT_1003_1: "形变指标统计",
    CONTENT_1003_2: "分析报告下载",
    CONTENT_1004: "灵活多样的使用方式",
    CONTENT_1005: "提供可选择、可上传等多种数据输入方式",
    CONTENT_1006: "智能化的风险筛查",
    CONTENT_1007: "结合专业算法自动分析房屋的形变指标",
    CONTENT_1008: "一键生成风险评估报告",
    CONTENT_1009: "针对单个房屋提供多层次的形变风险分析报告",
    CONTENT_1010: "施工过程监测",
    CONTENT_1011: "房屋维修维护",
    CONTENT_1012: "老旧房屋风险排查",
    CONTENT_1013: "商品住宅保险",
    CONTENT_1014: "写字楼安全管理",
    CONTENT_1015: "体育场馆隐患排查",
    CONTENT_2001:
      "以InSAR、offset-tracking等技术为代表的遥感大数据技术能获得大面积区域在一定时间内的地表形变情况，并根据形变特征发现相对活跃的区域，从而为地质灾害及其次生灾害发生的预警预防工作提供高效、可靠、大范围的低成本科技手段，真正体现“防”重于“治”，实现“人防”向“技防”的转变。",
    CONTENT_2002_01: "隐患识别",
    CONTENT_2002_02: "星地监测",
    CONTENT_2002_03: "预报预警",
    CONTENT_2002_04: "标绘、分析",
    CONTENT_2003: "智能隐患识别",
    CONTENT_2004: "自动识别风险区域筛选风险隐患",
    CONTENT_2005: "自动预报预警",
    CONTENT_2006: "自动识别风险隐患及时提醒",
    CONTENT_2007: "自由标绘个和分析",
    CONTENT_2008: "支持手动标绘绘图支持抢险救灾",
    CONTENT_2009: "隐患识别监督",
    CONTENT_2010: "火灾救援",
    CONTENT_2011: "其他抢险救灾",
    CONTENT_2012: "地质探测",
    CONTENT_2013: "写字楼安全管理",
    CONTENT_2014: "体育场馆隐患排查",
    CONTENT_3001:
      "基于InSAR技术，依据相关国家及行业标准，在城市大范围区域内，自动筛查出存在异常形变的建筑物，并对建筑物的异常形变等级进行划分，最终为建筑管理部门或建筑物安全鉴定部门提供技术支持。",
    CONTENT_3002_01: "城市普查",
    CONTENT_3002_02: "房屋建筑分析",
    CONTENT_3002_03: "形变分析",
    CONTENT_3002_04: "单体分析",
    CONTENT_3003: "灵活多样的使用方法",
    CONTENT_3004: "提供多样化框选方式支持导入边界分析",
    CONTENT_3005: "智能化的风险筛查",
    CONTENT_3006: "综合专业算法自动分析房屋的形变分析",
    CONTENT_3007: "综合风险评估报告",
    CONTENT_3008: "一键产出建筑风险报告支持单体分析",
    CONTENT_3009: "施工过程监测",
    CONTENT_3010: "房屋维修维护",
    CONTENT_3011: "老旧房屋风险排查",
    CONTENT_3012: "商品住宅保险",
    CONTENT_3013: "写字楼安全管理",
    CONTENT_3014: "体育场隐患排查",
    BUTTON_0001: "立即试用",
    BUTTON_0002: "立即使用",
    BUTTON_0003: "没有权限",
    BUTTON_0004: "您没有访问权限，如需获取权限，请与管理员联系！",
  },
  L_PROTOCOL: {
    TITLE: "东方至远云服务平台使用协议",
    CONTENT_0001:
      "东方至远云服务平台（以下简称“至远云”）使用协议，是您与北京东方至远科技股份有限公司（以下简称”至远科技”）就使用至远云服务的注册使用协议条款，请您仔细阅读，您访问、浏览或使用至远科技产品及任何服务即表明您已阅读、理解并同意受本条款的约束。",
    CONTENT_0002:
      "尊敬的至远云用户，欢迎您申请成为至远云产品的注册用户，我们致力于为您提供一站式服务！您若获得至远云产品账户，可在开通相应产品线的权限后，利用同一组用户名和密码访问授权的至远科技产品，如InSAR数据服务、房屋形变分析等。",
    CONTENT_0003: "注册资料",
    CONTENT_0004: "基于至远云所提供网络和技术服务的特殊性，您应同意：",
    CONTENT_0005: "（1）提供详尽、准确的注册信息和资料。",
    CONTENT_0006:
      "（2）不断更新注册资料，符合及时、详尽、准确的要求。如果您提供的资料包含有不正确的信息，至远云保留结束您使用该网络服务资格的权利。",
    CONTENT_0007: "登录步骤",
    CONTENT_0008: "要登录到至远云产品，请按以下步骤操作：",
    CONTENT_0009: "（1）在用户名字段中，输入您的至远云产品用户名；",
    CONTENT_0010: "（2）在密码字段中，输入您的密码；",
    CONTENT_0011: "（3）在验证码字段中，输入图示验证码；",
    CONTENT_0012: "（4）点击登录。",
    CONTENT_0013: "法律声明",
    CONTENT_0014:
      "至远云提醒您：在使用larkview.cn各项服务前，请您务必仔细阅读并透彻理解本声明。您可以选择不使用larkview.cn服务，但如果您选择使用larkview.cn服务，您的使用行为将被视为对本声明全部内容的认可。”larkview.cn”指由北京东方至远科技股份有限公司（简称”至远科技”）运营的网站，域名为：www.larkview.cn。",
    CONTENT_0015:
      "larkview.cn上关于larkview.cn注册或其发布的相关信息（包括但不限于用户名称、公司名称、联系人及联络信息，相关图片、视讯等）的信息均是由会员自行提供，会员依法应对其提供的任何信息承担全部责任。",
    CONTENT_0016:
      "任何单位或个人认为larkview.cn网页内容（包括但不限于larkview.cn会员发布的产品信息）可能涉嫌侵犯其合法权益，应该及时向至远科技提出书面权利通知，并提供身份证明、权属证明、具体链接（URL）及详细侵权情况证明。至远科技在收到上述法律文件后，将会依法尽快移除相关涉嫌侵权的内容。",
    CONTENT_0017:
      "larkview.cn转载作品（包括论坛内容）出于传递更多信息之目的，并不意味至远云（包括至远云关联企业）赞同其观点或证实其内容的真实性。",
    CONTENT_0018: "知识产权声明",
    CONTENT_0019:
      "至远科技拥有larkview.cn网站内所有信息内容（除larkview.cn会员发布的信息外，包括但不限于文字、图片、软件、音频、视频）的版权。",
    CONTENT_0020:
      "任何被授权的浏览、复制、打印和传播属于larkview.cn网站内信息内容都不得用于商业目的且所有信息内容及其任何部分的使用都必须包括此版权声明；",
    CONTENT_0021:
      "larkview.cn所有的产品、技术与所有分析报告等均属于至远科技的知识产权。“东方至远云服务平台”、“larkview.cn”、至远云其他产品服务名称及相关图形、标识等为至远科技的注册商标。未经北京东方至远科技股份有限公司许可，任何人不得擅自（包括但不限于：以非法的方式复制、传播、展示、镜像、上载、下载）使用。否则，至远科技将依法追究法律责任。",
    CONTENT_0022: "隐私权政策",
    CONTENT_0023:
      "至远科技尊重并保护所有使用larkview.cn服务用户的个人隐私权。为了给您提供更准确、更有个性化的服务，至远科技会按照本隐私权政策的规定使用和披露您的个人信息。但至远科技将以高度的勤勉、审慎义务对待这些信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，至远云不会将这些信息对外披露或向第三方提供。至远科技会不时更新本隐私权政策。您在同意至远云服务协议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于至远云服务协议不可分割的一部分。",
    CONTENT_0024: "1.适用范围",
    CONTENT_0025:
      "（1）在您注册至远云账户时，您根据至远云要求提供的个人或组织注册信息；",
    CONTENT_0026:
      "（2）在您使用larkview.cn服务，或访问larkview.cn网页时，至远云自动接收并记录的您的浏览器和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据；",
    CONTENT_0027: "（3）至远云通过合法途径从商业伙伴处取得的用户个人数据",
    CONTENT_0028: "您了解并同意，以下信息不适用本隐私权政策：",
    CONTENT_0029: "（1）您在使用larkview.cn提供时所发布的信息；",
    CONTENT_0030: "（2）您在使用larkview.cn提供的服务时，对外公布的信息；",
    CONTENT_0031:
      "（3）信用评价、违反法律规定或违反至远云规则行为及至远科技已对您采取的措施。",
    CONTENT_0032: "2. 信息使用",
    CONTENT_0033:
      "（1）至远科技不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的许可，或该第三方和至远科技单独或共同为您提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有这些资料。",
    CONTENT_0034:
      "（2）至远科技也不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何larkview.cn用户如从事上述活动，一经发现，至远科技有权立即终止与该用户的服务协议。",
    CONTENT_0035:
      "（3）为服务用户的目的，至远科技可能通过使用您的个人信息，向您提供您感兴趣的信息，包括但不限于向您发出产品和服务信息，或者与至远科技合作伙伴共享信息以便他们向您发送有关其产品和服务的信息（后者需要您的事先同意）。",
    CONTENT_0036: "3. 信息披露",
    CONTENT_0037:
      "在如下情况下，至远科技将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息：",
    CONTENT_0038: "（1）经您事先同意，向第三方披露；",
    CONTENT_0039:
      "（2）如您是适合的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；",
    CONTENT_0040:
      "（3）根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；",
    CONTENT_0041:
      "（4）如您出现违反中国有关法律、法规或者至远科技服务协议或相关规则的情况，需要向第三方披露；",
    CONTENT_0042:
      "（5）为提供您所要求的产品和服务，而必须和第三方分享您的个人信息；",
    CONTENT_0043:
      "（6）在larkview.cn上创建的某一交易中，如交易任何一方履行或部分履行了交易义务并提出信息披露请求的，至远科技有权决定向该用户提供其交易对方的联络方式等必要信息，以促成交易的完成或纠纷的解决。",
    CONTENT_0044: "（7）其它至远科技根据法律、法规或者网站政策认为合适的披露。",
    CONTENT_0045: "4. 信息存储和交换",
    CONTENT_0046:
      "至远云收集的有关您的信息和资料将保存在至远云及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或至远云收集信息和资料所在地的境外并在境外被访问、存储和展示。",
    CONTENT_0047: "5. Cookie的使用",
    CONTENT_0048:
      "（1）在您未拒绝接受cookies的情况下，至远云会在您的计算机上设定或取用cookies，以便您能登录或使用依赖于cookies的larkview.cn服务或功能。至远云使用cookies可为您提供更加周到的个性化服务，包括推广服务。",
    CONTENT_0049:
      "（2）您有权选择接受或拒绝接受cookies。您可以通过修改浏览器设置的方式拒绝接受cookies。但如果您选择拒绝接受cookies，则您可能无法登录或使用依赖于cookies的larkview.cn服务或功能。",
    CONTENT_0050: "（3）通过至远云所设cookies所取得的有关信息，将适用本政策；",
    CONTENT_0051: "6. 信息安全",
    CONTENT_0052:
      "（1）至远云将通过向其它服务器备份、对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您注意在信息网络上不存在绝对完善的安全措施。",
    CONTENT_0053:
      "（2）在使用larkview.cn服务进行网上交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或者邮政地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是至远云账户及密码发生泄露，请您立即联络至远科技客服，以便至远科技采取相应措施。",
    CONTENT_0054:
      "（3）根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；",
    CONTENT_0055: "免责",
    CONTENT_0056:
      "至远科技所提供的数据和信息仅供用户参考使用。所用数据均来源于合法渠道，根据国际和行业通行的准则，尽可能保证可靠、准确和完整，但并不保证报告所述信息的全面性和及时性。所提供的数据和信息不能作为工程勘查决策等的最终依据，不能作为道义的、责任的和法律的依据或者凭证，无论是否已经明示或者暗示。",
    CONTENT_0057:
      "至远科技提供的数据和信息均为北京东方至远科技股份有限公司所有，未经书面许可，任何机构和个人不得以任何形式翻版、复制和发布。如引用发布，需通过书面方式取得本公司同意，并注明出处为北京东方至远科技股份有限公司，且不得对本报告进行有悖原意的引用、删节和修改。",
    CONTENT_0058: "北京东方至远科技股份有限公司对本条款具有最终解释权。",
    CONTENT_0059:
      "至远科技将尽力提供一切可能的技术和设备维护用户的资料和账户安全，并努力为您提供最好的服务。但至远科技对您的下述损失免责：",
    CONTENT_0060:
      "（1）由于非故意或不可抗拒的原因（含系统维护和升级），导致的用户数据损失、服务停止，至远科技不承担赔偿及其他连带的法律责任。",
    CONTENT_0061:
      "（2）并非由于至远科技的故意或过失而导致至远云产品服务未能注册成功；",
    CONTENT_0062: "（3）由于您或您的代理人故意或过失导致您遭受损失的；",
    CONTENT_0063:
      "（4）由于至远云对服务系统进行系统测试、升级、更新、服务抽样测试，对您产生的任何影响。",
    CONTENT_0064:
      "（5）至远云有权随时删除您账户内含有任何危害国家安全，侵犯他人版权或人身权或其他合法权益等违法或有违社会公序良俗的内容。",
    CONTENT_0065: "其他",
    CONTENT_0066:
      "本协议与您与至远云就各个产品签订的具体服务协议内容有不一致之处的，以具体服务协议为准。",
  },
  L_REGISTER: {
    BANNER_0001: "欢迎注册",
    BUTTON_0001: "注册",
    TIP_0001: "已有账号？",
    TIP_0002: "立即登录",
    TIP_0003: "长度为4-20位的数字、字母、汉字、下划线",
    TIP_0004: "用户名设置不符合要求",
    TIP_0005: "该会员名已被使用。你可以:",
    TIP_0006: "1、重新输入或选择推荐的会员名",
    TIP_0007: "2、使用该会员名",
    TIP_0008: "登录",
    TIP_0009: "长度1~40的任意字符",
    TIP_0010: "公司名称设置不符合要求",
    TIP_0011: "长度为2-30的字符",
    TIP_0012: "姓名设置不符合要求",
    TIP_0013: "请选择从事行业",
    TIP_0014: "输入符合规范的手机号",
    TIP_0015: "手机号不符合规范",
    TIP_0016: "该手机号已被注册",
    TIP_0017: "输入符合规范的邮箱地址",
    TIP_0018: "邮箱地址不符合规范",
    TIP_0019: "该邮箱地址已被注册",
    TIP_0020: "密码设置不符合要求",
    TIP_0021: "长度为6-20位的数字、字母",
    TIP_0022: "请再次输入密码",
    TIP_0023: "两次输入的密码不一致，请重新输入",
    TIP_0024: "看不清，点击换一张。",
    TIP_0025: "请输入六位验证码",
    TIP_0026: "我已阅读并同意",
    TIP_0027: "《东方至远云服务平台使用协议》",
    TIP_0028: "请勾选同意协议",
    TIP_0029: "注册失败，请稍后再试",
    TIP_0030: "恭喜您，注册成功！",
    TIP_0031: "请耐心等待审核！",
    FORM_0001: "请输入用户名",
    FORM_0002: "请输入公司名称",
    FORM_0003: "请输入姓名",
    FORM_0004: "请输入手机号",
    FORM_0005: "请输入邮箱地址",
    FORM_0006: "请输入密码",
    FORM_0007: "请再次输入密码",
    FORM_0008: "请输入验证码",
  },
  L_USER: {
    BANNER_0001: "用户管理",
    TITLE_0001: "基本资料",
    TITLE_0002: "修改密码",
    BUTTON_0001: "保存",
    BUTTON_0002: "取消",
    FORM_0001: "用户名：",
    FORM_0002: "用户密码：",
    FORM_0003: "公司名称：",
    FORM_0004: "姓名：",
    FORM_0005: "联系电话：",
    FORM_0006: "邮箱地址：",
    FORM_0007: "原密码：",
    FORM_0008: "新密码：",
    FORM_0009: "确认新密码：",
    FORM_0010: "验证码：",
    TIP_0001: "请输入用户密码",
    TIP_0002: "长度为6-20位的数字、字母",
    TIP_0003: "密码格式错误",
    TIP_0004: "请输入公司名称",
    TIP_0005: "长度不少于4的汉字",
    TIP_0006: "公司名称不符合规范",
    TIP_0007: "请输入姓名",
    TIP_0008: "长度为2-30字符的英文,汉字,数字。一个汉字为两个字符。",
    TIP_0009: "姓名不符合规范",
    TIP_0010: "请输入手机号",
    TIP_0011: "输入符合规范的手机号",
    TIP_0012: "手机号不符合规范",
    TIP_0013: "请输入邮箱地址",
    TIP_0014: "输入符合规范的邮箱地址",
    TIP_0015: "邮箱地址不符合规范",
    TIP_0016: "请输入原始密码",
    TIP_0017: "长度为6-20位的数字、字母",
    TIP_0018: "密码格式不正确",
    TIP_0019: "请输入新密码",
    TIP_0020: "长度为6-20位的数字、字母",
    TIP_0021: "密码设置不符合要求",
    TIP_0022: "请再次输入新密码",
    TIP_0023: "请再次输入密码",
    TIP_0024: "两次输入的密码不一致，请重新输入",
    TIP_0025: "请输入验证码",
    TIP_0026: "看不清，点击换一张。",
    TIP_0027: "请输入六位验证码",
  },
  L_INSAR: {
    TITLE: "InSAR数据服务",
    MENU_0001: "我的数据",
    MENU_0002: "搜索地点",
    BUTTON_0001: "形变时序分析",
    BUTTON_0002: "分析报告",
    BUTTON_0003: "区域形变分析",
    BUTTON_0004: "清除",
    BUTTON_0005: "行政区划",
    BUTTON_0006: "剖面分析",
    BUTTON_0007: "",
    BUTTON_0008: "形变速率",
    BUTTON_0009: "确定",
    BUTTON_0010: "数据分布",
    BUTTON_0011: "等值线",
    BUTTON_0012: "图例设置",
    BUTTON_0013: "用户管理",
    BUTTON_0014: "设置透明度",
    BUTTON_0015: "定位您的城市",
    BUTTON_0016: "放大",
    BUTTON_0017: "缩小",
    BUTTON_0018: "底图设置",
    BUTTON_0019: "测量",
    BUTTON_0020: "鹰眼",
    CONTENT_0001: "用户：",
    CONTENT_0002: "剖面分析",
    CONTENT_0003: "0",
    CONTENT_0004: "100%",
    CONTENT_0005: "形变时序分析图",
    CONTENT_0006: "区域形变分析",
    CONTENT_0007: "数据分析列表",
    CONTENT_0008: "数据报告下载",
    CONTENT_0009: "数据报告",
    CONTENT_0010: "点",
    CONTENT_0011: "面",
    CONTENT_0012: "区块序号",
    CONTENT_0013: "面积",
    CONTENT_0014: "PS点数",
    CONTENT_0015: "面积/PS点数",
    CONTENT_0016: "最大形变速率",
    CONTENT_0017: "数据分析图表",
    CONTENT_0018: "平均形变速率",
    CONTENT_0019: "累积形变量",
    CONTENT_0020: "时间演化",
    CONTENT_0021: "没有数据",
    CONTENT_0022: "底图设置",
    CONTENT_0023: "普通地图",
    CONTENT_0024: "卫星地图",
    CONTENT_0025: "卫星地图（混合）",
    CONTENT_0026: "地形地图",
    CONTENT_0027: "测量",
    CONTENT_0028: "距离",
    CONTENT_0029: "面积",
    CONTENT_0030: "测量结果：",
    CONTENT_0031: "数据信息",
    CONTENT_0032: "卫星[波段]：",
    CONTENT_0033: "测量方式：",
    CONTENT_0034: "轨道方向：",
    CONTENT_0035: "影像期数：",
    CONTENT_0036: "开始时间：",
    CONTENT_0037: "结束时间：",
    CONTENT_0047: "用户名",
    CONTENT_0048: "退出",
    CONTENT_0049: "请输入漏斗统计值！",
    CONTENT_0050: "暂无数据",
    CONTENT_0051: "无数据",
    CONTENT_0052: "查无具体结果，建议加上行政区",
    CONTENT_0053: "此地暂无数据！",
    CONTENT_0054: "暂无分析报告！",
    CONTENT_0055: "共",
    CONTENT_0056: "条",
    CONTENT_0057: "到",
    CONTENT_0058: "期数：",
    CONTENT_0059: "省",
    CONTENT_0060: "原始形变量",
    CONTENT_0061: "趋势形变量",
    CONTENT_0062: "时间",
    CONTENT_0063: "数据",
    CONTENT_0064: "最多支持5个点查询，是否确定要清除地图上所有的分析点？",
    CONTENT_0065: "点间隔",
    CONTENT_0066: "m",
    CONTENT_0067: "km",
    CONTENT_0068: "m²",
    CONTENT_0069: "km²",
    CONTENT_0070: "点击标注",
    CONTENT_0071: "双击结束",
    CONTENT_0072: "多点查询",
    CONTENT_0073: "单点查询",
    CONTENT_0074: "绘制区域不能超过1平方公里！",
    CONTENT_0075: "全局",
    CONTENT_0076: "局部",
    CONTENT_0077: "参考点：",
    CONTENT_0078: "最多支持选择5个！",
    CONTENT_0079: "期",
    CONTENT_0080: "重置",
    CONTENT_0081: "应用",
    CONTENT_0082: "暂不支持对多个数据源同时操作！",
    PAGE_0001: "首页",
    PAGE_0002: "末页",
    PAGE_0003: "刷新",
    PAGE_0004: "<",
    PAGE_0005: ">",
  },
  L_HOUSE: {
    TITLE: "房屋形变分析",
    BUTTON_0001: "搜索地点",
    BUTTON_0002: "新建房屋风险分析",
    BUTTON_0003: "分析记录",
    BUTTON_0004: "显示PS点范围",
    BUTTON_0005: "清除",
    BUTTON_0006: "用户管理",
    BUTTON_0007: "删除",
    BUTTON_0008: "矩形选择",
    BUTTON_0009: "多边形选择",
    BUTTON_0010: "上传区域文件",
    BUTTON_0011: "是",
    BUTTON_0012: "否",
    BUTTON_0013: "提交",
    BUTTON_0014: "取消",
    BUTTON_0015: "查看分析记录",
    BUTTON_0016: "分析记录",
    BUTTON_0017: "关闭",
    BUTTON_0018: "查询",
    BUTTON_0019: "重置",
    BUTTON_0020: "新建房屋",
    BUTTON_0021: "定位您的城市",
    BUTTON_0022: "放大",
    BUTTON_0023: "缩小",
    BUTTON_0024: "底图设置",
    BUTTON_0025: "测量",
    BUTTON_0026: "鹰眼",
    BUTTON_0027: "普通地图",
    BUTTON_0028: "卫星地图",
    BUTTON_0029: "卫星地图（混合）",
    BUTTON_0030: "地形地图",
    BUTTON_0031: "距离",
    BUTTON_0032: "面积",
    BUTTON_0033: "测量结果：",
    BUTTON_0034: "用户名",
    BUTTON_0035: "退出",
    BUTTON_0036: "统计报告",
    BUTTON_0037: "排查结果",
    CONTENT_0001: "新建窗口",
    CONTENT_0002: "选择房屋区域：",
    CONTENT_0003: "选中后在地图上拉框选择矩形区域",
    CONTENT_0004: "选中后在地图上选择多边形区域",
    CONTENT_0005: "选择.shp, .shx, .dbf 三种格式的文件",
    CONTENT_0006: "请把.shp, .shx, .dbf 三种格式的文件拖放到这个区域",
    CONTENT_0007: "设置报告信息",
    CONTENT_0008: "报告类型：",
    CONTENT_0009: "房屋形变报告",
    CONTENT_0010: "下载报告：",
    CONTENT_0011: "新建房屋形变分析",
    CONTENT_0012: "正在提交中，请耐心等待...",
    CONTENT_0013: "分析记录",
    CONTENT_0014: "申请时间：",
    CONTENT_0015: "选择开始时间",
    CONTENT_0016: "选择终止时间",
    CONTENT_0017: "至",
    CONTENT_0018: "审核状态：",
    CONTENT_0019: "原始形变量",
    CONTENT_0020: "趋势形变量",
    CONTENT_0021: "形变指标",
    CONTENT_0022: "设置记录名称",
    CONTENT_0023: "记录名称：",
    CONTENT_0024: "继续新建",
    CONTENT_0025: "统计报告",
    CONTENT_0026: "搜索城市名称",
    CONTENT_0027: "序号",
    CONTENT_0028: "城市名称",
    CONTENT_0029: "操作",
    CONTENT_0030: "用户：",
    CONTENT_0031: "沉降曲线",
    CONTENT_0032: "倾斜曲线",
    CONTENT_0033: "上一页",
    CONTENT_0034: "下一页",
    CONTENT_0035: "杭州市",
    CONTENT_0036: "查无具体结果，建议加上行政区",
    CONTENT_0037: "正在提交中，请耐心等待...",
    CONTENT_0038: "请选择报告类型",
    CONTENT_0039: "请选择审核状态",
    CONTENT_0040: "请选择房屋区域！",
    CONTENT_0041: "请选择有PS点的区域绘制！",
    CONTENT_0042: "绘制区域不能超过2平方公里！",
    CONTENT_0043: "请选择是否下载报告！",
    CONTENT_0044: "很遗憾，您选择的区域没有PS点！",
    CONTENT_0045: "请点击【分析记录】查看房屋分析信息。",
    CONTENT_0046: "恭喜您，提交成功！",
    CONTENT_0047: "个PS点，请点击【分析记录】查看房屋分析信息。",
    CONTENT_0048: "操作失败，请稍后再试！",
    CONTENT_0049: "请点击【分析记录】查看房屋分析信息。",
    CONTENT_0050: "您确定删除吗？",
    CONTENT_0051: "请选择三个有效文件！",
    CONTENT_0052: "文件大小不超过1M !",
    CONTENT_0053: "文件上传失败！",
    CONTENT_0054: "申请时间",
    CONTENT_0055: "审核状态",
    CONTENT_0056: "数据年份",
    CONTENT_0057: "PS点数",
    CONTENT_0058: "操作",
    CONTENT_0059: "定位",
    CONTENT_0060: "查看",
    CONTENT_0061: "号建筑物",
    CONTENT_0062: "号",
    CONTENT_0063: "",
    CONTENT_0064: "查无数据！",
    CONTENT_0065: "下载",
    CONTENT_0066: "关键点",
    CONTENT_0067: "日期",
    CONTENT_0068: "形变量/mm",
    CONTENT_0069: "房屋编码 ：",
    CONTENT_0070: "评估等级 ：",
    CONTENT_0071: "房屋分类：",
    CONTENT_0072: "整体累积沉降量 ：",
    CONTENT_0073: "累积倾斜量 ：",
    CONTENT_0074: "近期持续沉降时长：",
    CONTENT_0075: "近期持续沉降速率：",
    CONTENT_0076: "近期持续倾斜时长 ：",
    CONTENT_0077: "近期持续倾斜速率：",
    CONTENT_0078: "房屋编码：",
    CONTENT_0079: "监测时间：",
    CONTENT_0080: " ：",
    CONTENT_0081: "暂无数据",
    CONTENT_0082: "倾斜量",
    CONTENT_0083: "选择的房屋无数据，请重新选择",
    CONTENT_0084: "确定要删除所有吗？",
    CONTENT_0085: "共",
    CONTENT_0086: "m",
    CONTENT_0087: "km",
    CONTENT_0088: "m²",
    CONTENT_0089: "km²",
    CONTENT_0090: "全选",
    CONTENT_0091: "没有有效数据",
    CONTENT_0092: "双击结束",
    CONTENT_0093: "房屋列表",
    CONTENT_0094: "搜索关键字",
    CONTENT_0095: "选择评估等级",
    CONTENT_0096: "房屋编码",
    CONTENT_0097: "房屋分类",
    CONTENT_0098: "评估等级",
    CONTENT_0099: "下载",
    CONTENT_0100: "名称",
    CONTENT_0101: "参考点",
    CONTENT_0102: "删除",
    CONTENT_0103: "确定删除此订单？",
    CONTENT_0104: "暂不具备分析条件",
    CONTENT_0105: "请选择有效的房屋区域",
    CONTENT_0106: "年",
    CONTENT_0107: ">6个月",
    CONTENT_0108: "个月",
    CONTENT_0109: "mm/月",
    CONTENT_0110: "‰/月",
    CONTENT_0111: "查看报告",
    CONTENT_0112:
      "{label}失败：当前没有选中任何数据或没有选中有效数据，请重新选择！",
    CONTENT_0113: "请点击【分析记录】查看房屋分析信息。",
    PAGE_0001: "首页",
    PAGE_0002: "末页",
    PAGE_0003: "刷新",
    PAGE_0004: "<",
    PAGE_0005: ">",
  },
  L_SYSTEM_TIP: {
    TIP_0001: "系统提示",
    TIP_0002: "确定",
    TIP_0003: "是",
    TIP_0004: "否",
  },
  L_RESTPASSWORD: {
    BANNER_0001: "手机号码验证",
    BANNER_0002: "密码设置",
    BUTTON_0001: "下一步",
    BUTTON_0002: "提交",
    BUTTON_0003: "获取验证码",
    TIP_0014: "输入符合规范的手机号",
    TIP_0001: "手机号不符合规范",
    TIP_0002: "该手机号已被注册",
    TIP_0003: "密码设置不符合要求",
    TIP_0004: "长度为6-20位的数字、字母",
    TIP_0005: "请再次输入密码",
    TIP_0006: "两次输入的密码不一致，请重新输入",
    TIP_0007: "看不清，点击换一张。",
    TIP_0008: "请输入六位验证码",
    TIP_0009: "验证码发送失败！",
    TIP_0010: "手机号校验失败，请稍后再试。",
    TIP_0011: "操作失败请稍后再试！",
    TIP_0012: "重置密码失败！请稍后再试。",
    TIP_0013: "重置密码成功！请重新登录。",
    TIP_0015: "您的账号已过期，请与管理员联系！",
    FORM_0001: "请输入手机号",
    FORM_0002: "请输入密码",
    FORM_0003: "请再次输入密码",
    FORM_0004: "请输入验证码",
  },
  L_DO: {
    APPLYSTATUS: "APPLYSTATUS",
    HOUSEDANGERGRADE: "HOUSEDANGERGRADE",
    INDUSTRY: "INDUSTRY",
    REPORTAPPLYSTATE: "REPORTAPPLYSTATE",
    REPORTTYPE: "REPORTTYPE",
  },
};
export default zh;
